<template>
	<div class="my">
		<template v-if="token">
			<div class="top">
				<div class="user">
					<div class="avatar">
						<img v-if="userInfo.headImage" :src="userInfo.headImage" alt="" />
						<img v-else src="../asset/images/userdefault.png" alt="" />
					</div>
					<div class="name">{{ userInfo.name }}</div>
				</div>
			</div>
			<div class="content">
                <div class="exit" @click="exit">退出登录</div>
            </div>
            
		</template>

		<template v-else>
			<div class="top">
				<div class="user">
					<div class="avatar">
						<img v-if="userInfo.headImage" :src="userInfo.headImage" alt="" />
						<img v-else src="../asset/images/userdefault.png" alt="" />
					</div>
				</div>
			</div>
			<div class="content">
				<div class="item">
					<div class="left">参培机构</div>
					<div class="right" @click="goLogin">登录</div>
				</div>
                <!-- c平台跳转过来不展示学员登录入口 -->
				<div class="item" v-if="fromUrl !== 'c_platform'">
					<div class="left">学员</div>
					<div class="right" @click="goStudent">登录</div>
				</div>
				<div class="item last">
					<div class="left">教学资源合作机构</div>
					<div class="right needCopyBtn" @click="copyUrl">复制链接</div>
					<div class="empty"></div>
					<div class="middle">{{adminUrl}}</div>
					<div class="bottom">请复制上方链接，到电脑端登录</div>
				</div>
			</div>
		</template>
	</div>
</template>
<script>
import { mapState } from 'vuex'
import Clipboard from 'clipboard'
import { Toast } from 'vant'
export default {
	name: 'my',
	data() {
		return {
			token: '',
            adminUrl: '',
			fromUrl:'',
			disabled:window.location.hostname.includes('ethrss') && !window.location.hostname.includes('jiafu')

		}
    },
	computed: {
		...mapState(['userInfo']),
	},
	mounted() {
        this.token = localStorage.getItem('token')
        this.getAdminUrl()
        // c_platform 代表由c平台跳转到该应用
        this.fromUrl = sessionStorage.getItem('fromUrl')
	},
	methods: {
		goLogin() {
			this.$router.push('/login')
		},
		goStudent() {
			let hostname = window.location.hostname.split('.')
			let env = hostname[hostname.length - 3]
			let arr = ['testing3', 'fat', 'uat']
			let url = {}
			
			const hostname2 = window.location.hostname
			if(hostname2.includes('ethrss') && !hostname2.includes('jiafu')){
				url = {
					testing3: 'http://12639648.channel-h5.testing3.wdeduc.com/login',
					fat: 'https://12655955.channel-h5.fat.wdeduc.com/login',
					uat: 'https://57796486.channel-h5.uat.wdeduc.com/login',
				}
			}	else if(hostname2.includes('mohrss')) {
				//
				url = {
					// testing3: 'http://12639648.channel.testing3.wdeduc.com/login',
					// fat: 'https://12655955.channel.fat.wdeduc.com/login',
					uat: 'https://32156444.channel-h5.uat.wdeduc.com/',
				}
			}	
			if (arr.includes(env)) {
				// 此时不是真正的生产环境
				window.open(url[env], '_blank')
			} else {
				if((/ethrss-h5.wdeduc.com*/).test(window.location.hostname)){
					window.open('https://43515236.channel-h5.wdeduc.com/login', '_blank')
				}	else if((/jiafu-m.ethrss.cn*/).test(window.location.hostname)) {
					window.open('https://18188548.jiafu-m.ethrss.cn/login', '_blank')
				}	
			}
		},
		getAdminUrl() {
			let hostname = window.location.hostname.split('.')
			let env = hostname[hostname.length - 3]
			let arr = ['testing1', 'testing2', 'testing3', 'fat', 'uat']

			const hostname2 = window.location.hostname
			if(hostname2.includes('jiafu')) {
				//
				if (arr.includes(env)) {
					// 此时不是真正的生产环境
					this.adminUrl = `https://jiafu-admin.${env}.ethrss.cn`
				} else {
					this.adminUrl = 'https://jiafu-admin.ethrss.cn'
				}
			}else if(hostname2.includes('mohrss')){
				if (arr.includes(env)) {
					// 此时不是真正的生产环境
					this.adminUrl = `https://mohrss-admin.${env}.wdeduc.com`
				} else {
					this.adminUrl = 'https://mohrss-admin.wdeduc.com'
				}
			}else{
				if (arr.includes(env)) {
					// 此时不是真正的生产环境
					this.adminUrl = `https://ethrss-admin.${env}.wdeduc.com`
				} else {
					this.adminUrl = 'https://ethrss-admin.wdeduc.com'
				}
			}
		},
		copyUrl() {
			let clipboard = new Clipboard('.needCopyBtn', {
				text: () => {
					return this.adminUrl
				},
			})
			clipboard.on('success', (e) => {
				Toast({
					message: '复制成功',
					duration: 1000,
				})
				console.log('复制 成功')
				// 释放内存
				clipboard.destroy()
			})
			clipboard.on('error', (e) => {
				Toast({
					message: '复制失败',
					duration: 1000,
				})
				clipboard.destroy()
			})
        },
        async exit(){
            const params = { token: this.token }
            await this.$api.usercenter.postAccounLogout({ params })
            this.token = ''
            const saasId = localStorage.getItem('saasId')
            sessionStorage.clear()
            if(this.fromUrl){
                sessionStorage.setItem('fromUrl',this.fromUrl)
            }
			localStorage.clear()
			localStorage.setItem('saasId', saasId)
			// this.$router.push('/home')
        }
	},
}
</script>
<style lang="stylus" scoped>
@import '../asset/css/my.styl';
.grey{
	background:#ccc!important;
}
</style>
